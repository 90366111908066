import React, { useState } from "react"
import loadable from "@loadable/component"
import "../../css/typography-overpass.css"
const ChatComponent = loadable(() => import("../../components/Chat Iframe"))

function Chat() {
  const [hideSendBox, setHideSendBox] = useState(true)
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  return (
    <ChatComponent
      name="regioit"
      token="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzUxNTk1MDUuMjUyOTYxLCJleHAiOjIzMDU4Nzk1MDUuMjUyOTYxLCJib3RJZCI6ImRkeTE1OW44bDNqdnl1Z3UiLCJpc3MiOiJjb252YWlzZSIsImF1ZCI6ImNvbnZhaXNlLWNvdXJpZXIifQ.rGHzq5_QDVCgLW0USGnNc3u7xzaYOKrnRp44jd0Qo-QWTy-Kj_VMyj1HWIht8T8eWDf_5NJNMfkFRlYGs2K04vJnqoDlDyS5dpMjfdMk30daCviLdtHleG7OwIKLuGompMiX4O0rK-q6-_188AqYCgAEjtZu1a_EyXk4CzA_ne6vSCkoNIgJozw3kEZPN5acB337_fefMyLqVsrqF9ECflm5Jbx5_TSbzxKUjttf4lhTYVv2svnYJHyjxzPlgoqNPgeoVJ9Sv6kSbp93Yn7HJKM0q2njqzucT1InMLBqXM2s5RSCtAtqsZTEKIqfHHxol_YvJpZWQ6eC3KBzmNlucK65je_yQKBzK9TMwbBJayAJ0X_1YrkuQ3xVRUfrwNbqWwkj6cuOabWnZrHjF87IBYmeDLUzDjhPP9w--8YFBS84pDbcZvPCm6fCpYh6eyM5JoLjGtBwJ3kCvUCzE2GX_QIkymwlNrV0VeDJYtprLsdWT6LT9pAoEhpPkh3nIC06VBKk8daJnjbXDwH9S8mG5d0yRnRZrYpdWSLloIN6PDq-j2QAbLcwKKmQ73yguN5KVYo1KS5ush2AQh_vMpTtLs-8LLcKlpjEgkmTVgLsQSwAtrXzwNVK6B8R40ywkBaS-olu13mcUxa_FMlzy1vQ3ozLm9NrmqZmMOyNpayKdW0"
      botId="ddy159n8l3jvyugu"
      domain="https://courier.convaise.com/frontend/v3/directline"
      // domain="http://localhost:8000/v3/directline"
      webSocket={true}
      hasGDPR={false}
      onHideSendBox={value => setHideSendBox(value)}
      autoFocusVersion="v1"
      // gdprButtonText="Zustimmen"
      // gdprText={
      //   <>
      //     Die{" "}
      //     <a
      //       href="https://www.gegenbauer.de/datenschutzerklaerung/"
      //       target="_blank"
      //     >
      //       Datenschutzerklärung
      //     </a>{" "}
      //     habe ich zur Kenntnis genommen und ich erkläre mich damit
      //     einverstanden, dass meine Daten zum Zwecke der Bearbeitung meiner
      //     Anfrage verarbeitet werden. Ihre Einwilligung können Sie jederzeit
      //     widerrufen. Durch den Widerruf der Einwilligung wird die
      //     Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
      //     erfolgten Verarbeitung nicht berührt.
      //   </>
      // }
      styleOptions={{
        primaryFont:
          'Overpass,sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "#134d88",
        botAvatarBackgroundColor: "#fff",
        hideSendBox,
        transcriptVisualKeyboardIndicatorWidth: 1,
        transcriptActivityVisualKeyboardIndicatorWidth: 1,
        // hideSendBox: true,
        // primaryFont:
        //   'muli,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      }}
      adaptiveCardsHostConfig={{
        containerStyles: {
          emphasis: {
            backgroundColor: "#eee",
            foregroundColors: {
              default: {
                default: "#fff",
                subtle: "#484644",
              },
            },
          },
          accent: {
            backgroundColor: "rgb(199, 222, 249)",
            foregroundColors: {
              default: {
                default: "#fff",
                subtle: "#484644",
              },
            },
          },
        },
        actions: {
          actionAlignment: "stretch",
          actionsOrientation: "horizontal",
          buttonSpacing: 8,
          maxActions: 100,
          showCard: {
            actionMode: "inline",
            inlineTopMargin: 8,
          },
          spacing: "default",
        },
      }}
      // styleCustomizations={
      //   {
      // "--button-blue": "#E30014",
      // "--button-blue-light": "#C30C0E",
      // "--button-orange": "#2d3234",
      // "--button-orange-light": "#000",
      // "--button-orange-text": "#fff",
      // "--button-red": "#E30014",
      // "--button-red-light": "#C30C0E",
      // "--button-green": "rgba(40, 167, 69, 1)",
      // "--button-green-light": "rgba(40, 167, 69, 0.8)",
      // "--font":
      //   'Overpass,sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      // "--button-font-size": "16px",
      // "--button-font-weight": "400",
      // "--button-border-radius": "0",
      // "--button-box-shadow": "rgb(0 0 0 / 25%) 0px 0px 20px 0px",
      // "--button-padding": "8px 24px !important",
      // "--button-height": "42px",
      // "--card-border-radius": "5px",
      // "--font-size-normal": "16px",
      // "--line-height-normal": "26px",
      // "--font-size-large": "36px",
      //   }
      // }
      overrideLocalizedStrings={{
        TEXT_INPUT_PLACEHOLDER: "Ihre Frage oder gesuchte Dienstleistung...",
      }}
      styleCustomizations={{
        "--button-blue": "#134d88",
        "--button-blue-light": "#5a82ac",
        "--button-red": "rgb(120, 125, 141)",
        "--button-red-light": "rgba(120, 125, 141, 0.8)",
      }}
      // css="https://static.convaise.com/webchat/v4/convaise-assistant-min_v4-1.css"
    />
  )
}

export default Chat
